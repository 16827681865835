@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #153eaf;
  --secondary-color: #a1a1aa;
  --warning-color: rgb(251 146 60);
  --error-color: red;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Table border for sheetJS */
table{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
}

th, td {
  border: 1px solid #eee;
  text-align: center;
}

td{ cursor:pointer; font-weight: 250;}

td:hover {background-color: var(--secondary-color); color: white;}

a{
  color: var(--primary-color);
}

.dropdown:hover > .dropdown-content {
	display: block;
}