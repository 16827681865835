.scroll-to-top-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: none;
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .scroll-to-top-button.visible {
    display: block;
  }